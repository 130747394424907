<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('pp_museum_info.museum_information')}}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.organization')}}<span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="orgList"
                          id="org_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col class="col-sm-2">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
<!-- ===================Details Information start=============================== -->
            <b-row v-if="showDetails">
              <b-col lg="12" sm="12" class="mt-6">
                <b-table-simple bordered hover small caption-top responsive>
                  <tbody>
                    <tr>
                      <th class="text-right" style="width: 20%">{{ $t('globalTrans.organization') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td class="text-left" style="width: 20%">{{ ($i18n.locale === 'bn') ? museum.org_name_bn : museum.org_name }}</td>
                      <th class="text-right" style="width: 25%">{{$t('vm_museum_management.museum_name')}}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td class="text-left" style="width: 20%">{{ ($i18n.locale === 'bn') ? museum.museum_name_bn : museum.museum_name }}</td>
                    </tr>
                    <tr>
                      <th class="text-right">{{ $t('pp_museum_info.description') }}</th>
                      <th class="text-center">:</th>
                      <td class="text-left">{{ ($i18n.locale === 'bn') ? museum.description_bn : museum.description }}</td>
                      <th class="text-right">{{ $t('pp_museum_info.other_information') }}</th>
                      <th class="text-center">:</th>
                      <td class="text-left">{{ ($i18n.locale === 'bn') ? museum.other_information_bn : museum.other_information }}</td>
                    </tr>
                    <tr>
                      <th class="text-right">{{ $t('pp_museum_info.location') }}</th>
                      <th class="text-center">:</th>
                      <td class="text-left">{{ museum.location }}</td>
                      <th class="text-right">{{ $t('pp_museum_info.contact_us') }}</th>
                      <th class="text-center">:</th>
                      <td class="text-left">{{  museum.contact_us }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-row v-if="detailsNotFound">
              <b-col lg="12" sm="12" class="mt-6">
                <h3>Museum not found</h3>
              </b-col>
            </b-row>
<!-- ===================Details Information end================================= -->
            </template>
        </iq-card>
    </b-container>
</template>
<script>
// import { core } from '@/config/pluginInit'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { museumInfoByOrgApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        // Form, Details
    },
    data () {
        return {
            search: {
                org_id: 0
            },
            museum: {},
            items: '',
            showDetails: false,
            detailsNotFound: false
        }
    },
    computed: {
        orgList: function () {
          // const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          const orgDataList = this.$store.state.VirtualMuseum.commonObj.organizationProfileList.filter(sinOrg => sinOrg.status === 0)
          // const orgDataList = this.$store.state.commonObj.organizationProfileList
          return orgDataList.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
        }
    },
    async created () {
        // this.loadData()
    },
    methods: {
        details (item) {
          this.items = item
        },
        async searchData () {
           if (this.search.org_id > 0) {
              this.loadData()
           } else {
             this.showDetails = false
            this.detailsNotFound = false
           }
        },
        loadData () {
           this.showDetails = false
           this.detailsNotFound = false
           const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            // this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.postData(virtualMuseumServiceBaseUrl, museumInfoByOrgApi, params).then(response => {
                if (response.success) {
                   this.museum = this.getRelationalData(response.data)
                    this.showDetails = true
                    this.detailsNotFound = false
                } else {
                  this.detailsNotFound = true
                  this.showDetails = false
                }
            })
        },
        getRelationalData (data) {
            let orgFindObj = {}
                orgFindObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === data.org_id)
                const orgObj = {
                    org_name: orgFindObj !== undefined ? orgFindObj.text_en : '',
                    org_name_bn: orgFindObj !== undefined ? orgFindObj.text_bn : ''
                }
                return Object.assign({}, data, orgObj)
    }
    }
}
</script>

<style scoped>
  .modal-title {
    flex-grow: 1 !important;
  }
  th, td {
    border-color: none !important;
    border-color: #777 !important;
}
</style>
